/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
    display: block !important;
}

.iti .dropdown-menu.country-dropdown {
    margin-top: -1px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: var(--surface-border);
}

.iti .iti__country-list {
    margin-left: 0;
    width: 23.125rem;
    box-shadow: none;
    max-height: 15.625rem;
    font-size: var(--font-body);
    color: var(--text-color-secondary);
    background: var(--surface-overlay);
    border: 1px solid var(--surface-border);
}

.iti__flag-container.open + input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.iti .search-container input {
    font-size: var(--font-body);
    border-radius: 0;
    padding: 5px 10px;
    border: 1px solid var(--surface-border);
}

.iti .search-container input:focus {
    outline: none;
}

@media screen and (max-width: 47.9rem) {
    .iti .iti__country-list {
        width: 88.3vw;
    }
}

ngx-intl-tel-input input {
    width: 100%;
    padding: 10.5px;
    border-radius: 6px;
    font-size: var(--font-body);
    line-height: var(--line-height-150);
    background: var(--surface-ground);
    border: 1px solid var(--surface-border);
    &:hover {
        border-color: #93c5fd;
    }
    &:focus {
        outline: 0 none;
        outline-offset: 0;
        border-color: #93c5fd;
        box-shadow: var(--focus-ring);
    }
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
        color: var(--text-color-secondary);
    }
}

ngx-intl-tel-input input[disabled] {
    background-color: #e5eaf1;
}
