.custom_small_checkbox {
    &.p-checkbox {
        justify-content: flex-end;
        .p-checkbox-box {
            opacity: 1;
            width: 15px;
            height: 15px;
            border-radius: 0;
            background: var(--surface-ground);
            border-color: var(--primary-color);
            &.p-highlight {
                background: var(--primary-color);
                border-color: var(--primary-color);
            }
        }
    }
}

.custom_rounded_checkbox {
    .p-checkbox-box {
        opacity: 1;
        border-radius: 50%;
    }
}

.p-checkbox-label {
    opacity: 1 !important;
}

.custom_primary_border_checkbox {
    .p-checkbox-box {
        border-color: var(--primary-color);
    }
}
