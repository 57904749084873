@import "styles/main.scss";


/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: inter;
  src: url(assets/font/Inter-Regular.ttf);
}
* {
  font-family: inter;
}

body {
  margin: 0 !important;
  color: var(--text-color);
  font-size: var(--font-body);
  line-height: var(--line-height-120);
  background-color: var(--surface-ground);
}
html {
  font-size: 100%;
  @media screen and (max-width: 3900px) {
    font-size: 225%;
  }
  @media screen and (max-width: 2560px) {
    font-size: 138%;
  }
  @media screen and (max-width: 2000px) {
    font-size: 102%;
  }

  @media screen and (max-width: 1550px) {
    font-size: 95%;
  }
  @media screen and (max-width: 1370px) {
    font-size: 92%;
  }
  @media screen and (max-width: 1100px) {
    font-size: 90%;
  }

  @media screen and (max-width: 900px) {
    font-size: 70%;
  }
  @media screen and (max-width: 750px) {
    font-size: 30%;
  }
}
.marginBottom10px {
  margin-bottom: 0.625rem;
}
.marginBottom20px {
  margin-bottom: 20px !important;
}
.width100 {
  width: 100%;
}
.content {
  left: 16rem;
  top: 7.5vh;
  width: calc(100vw - 16rem);

  height: 92vh;
  overflow: auto;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  &.sidebar-collapse {
    left: 6vw;
    width: calc(100% - 6vw);
  }
  .pointer{
    cursor: pointer !important;
  }
  @media screen and (max-width: 1400px) {
    left: 12rem;
    width: calc(100% - 12rem);
  }
}
.table-section {
  padding: 1.25rem;
  //min-height: 85vh;
  padding-bottom: 0 !important;
  width: 100%;
  overflow: hidden;
}

.breadcrumb__container {
  margin: 0.625rem 1.5rem;
}
.name-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 10rem;
  display: block;
  position: relative;
  white-space: nowrap;
}
.marginRight10px {
  margin-right: 0.625rem !important;
}
.marginBottom20px {
  margin-bottom: 1.25rem !important;
}
.position-relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
sup {
  color: red;
}
.textAlignRight {
  text-align: right;
}
.displayFlex {
  display: flex;
  padding: 0.5rem 0;
}
.marginTop2rem {
  margin-top: 2rem;
}
.marginTophalfrem {
  margin-top: 0.5rem !important;
}
.marginBottomZero {
  margin-bottom: 0px !important;
}
.marginRight1rem {
  margin-right: 1rem;
}
.fontMid {
  font-size: var(--font-body);
}
.marginBottom1rem {
  margin-bottom: 1rem;
}
.marginBottom2rem {
  margin-bottom: 2rem;
}
.cursor-pointer {
  cursor: pointer;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
::ng-deep .quesitonsSection .p-accordion-tab::before {
  border-left: 2px solid red;
}

/* Track */
::-webkit-scrollbar-track {
  background: #4f4e4e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a3a0a0;
}

// .p-float-label label{
// font-size: var(--font-small)  !important;
// }

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.5rem;
}

.p-field > * {
  display: block;
}
.block{
  display: block !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.45rem 1.25rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.45rem 1.25rem;
  white-space: pre-line;
}

.p-datepicker table td > span {
  width: 1.3rem;
  font-size: 0.8rem;
  height: 1.1rem;
}

.p-datepicker table th > span {
  font-size: 0.8rem;
}
.p-fileupload .p-fileupload-buttonbar {
  padding: 0.5rem;
}
.p-fileupload .p-fileupload-row > div img {
  width: 30% !important;
}
.p-fileupload .p-fileupload-content {
  padding: 1rem;
}

:host ::ng-deep .p-radioButton {
  vertical-align: unset !important;
}

.p-radiobutton .p-radiobutton-box {
  border-color: #93c5fd;
  width: 21px;
  height: 21px;
}
.p-tabview-nav-content::-webkit-scrollbar {
  display: block !important;
}
cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
}
.p-tabview-nav-content {
  scrollbar-width: auto !important;
}