.reduced_dialog_size {
    &.p-dialog {
        width: 30vw !important;
        & .p-dialog-content {
            padding: 0 1.5rem 1.5rem;
        }
    }
}

.color_code_dialog_size {
    &.p-dialog {
        width: 55vw !important;
        & .p-dialog-content {
            padding: 0 1.5rem 1.5rem;
        }
    }
}
